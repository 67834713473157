/* eslint-disable*/
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import * as Sentry from '@sentry/vue';
import config from './config';
import { createPinia, PiniaVuePlugin } from 'pinia';
import App from './App.vue';
import router from './router';
import '../node_modules/@poplar-studio/design-system/src/index.scss';

Vue.use(VueCookies);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

Vue.config.productionTip = false;

Sentry.init({
    Vue,
    dsn: 'https://cf3064a411784c6b89de17438744b5a9@o622134.ingest.sentry.io/6102120',
    environment: config.env,
});

new Vue({
    router,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
