import {
    IFullCompany,
    ICurrency,
    IUnitTypes,
    GenericOrderingEnum,
    OrderingDirectionsEnum,
} from '@poplar-studio/poplar-cms';
import { Iitem } from '../types';
import Api from '@/services/api';
import { defineStore } from 'pinia';

const defaultCompany: IFullCompany = {
    id: '',
    defaultUnitTypeId: '',
    logo: '',
    currency: ICurrency.GBP,
    font: '',
    name: '',
    theme: 'light',
    colors: {
        button: '#000000',
        buttonFont: '#ffffff',
        menuItems: '#454545',
        selectedItems: '#000000',
        backgroundColor: '#e9e9e9',
        buttonDark: '#ffffff',
        buttonFontDark: '#000000',
        menuItemsDark: '#cccccc',
        selectedItemsDark: '#ffffff',
    },
    preferences: {
        convertImages: false,
        enhanced2d: false,
        solidBackground: true,
        usePixotronics: false,
        mediaOrder: {
            field: GenericOrderingEnum.UPDATED_AT,
            direction: OrderingDirectionsEnum.DESC,
        },
        categoryOrder: {
            field: GenericOrderingEnum.CREATED_AT,
            direction: OrderingDirectionsEnum.ASC,
        },
        enableShadows: true,
    },
};

interface ICompany {
    active_media_count: number;
    active_parent_count: number;
    active_variant_count: number;
    id: string;
    logo: string | null | undefined;
    media_count: number;
    name: string;
    parent_count: number;
    variant_count: number;
}

interface CompanyState {
    company: IFullCompany;
    companies?: ICompany[];
    currencyOptions: string[];
}

export const useCompanyStore = defineStore({
    id: 'company',
    state: (): CompanyState => ({
        company: defaultCompany,
        companies: [],
        currencyOptions: Object.values(ICurrency),
    }),

    actions: {
        async getCompany(id: string) {
            const response = await Api.getCompany(id);
            this.company = response.data.company;
            if (response.data.company.unitTypes && response.data.company.unitTypes.length > 0) {
                this.company.unitTypeIds = response.data.company.unitTypes.map(
                    (unitType: IUnitTypes) => unitType.id
                );
            }
        },
        async updateCompany(id: string, company: IFullCompany) {
            const body = {
                company,
            };
            await Api.updateCompany(id, body);
            await this.getCompany(id);
        },
        async createCompany(company: IFullCompany) {
            const body = {
                company,
            };
            // @ts-ignore
            delete body.company.id;
            const { data } = await Api.createCompany(body);
            return data;
        },
        async getAllCompanies() {
            const { data } = await Api.getAllCompanies();
            this.companies = data.companies;
        },
        async addCompanyCategory(body: Iitem) {
            await Api.addCompanyCategory(this.company.id, {
                category: {
                    ...body,
                },
            });
            await this.getCompany(this.company.id);
        },
        async updateCompanyCategory(id: string, body: Iitem) {
            await Api.updateCompanyCategory(
                this.company.id,
                {
                    category: {
                        ...body,
                    },
                },
                id
            );
            await this.getCompany(this.company.id);
        },
        async deleteCompanyCategory(id: string) {
            await Api.deleteCompanyCategory(this.company.id, id);
            await this.getCompany(this.company.id);
        },
        async addCompanyOrigin(body: Iitem) {
            await Api.addCompanyOrigin(this.company.id, {
                origin: {
                    url: body.name,
                },
            });
            await this.getCompany(this.company.id);
        },
        async updateCompanyOrigin(id: string, body: Iitem) {
            await Api.updateCompanyOrigin(
                this.company.id,
                {
                    origin: {
                        url: body.name,
                        id: body.id,
                    },
                },
                id
            );
            await this.getCompany(this.company.id);
        },
        async deleteCompanyOrigin(id: string) {
            await Api.deleteCompanyOrigin(this.company.id, id);
            await this.getCompany(this.company.id);
        },
        resetCompany() {
            this.company = defaultCompany;
        },
    },
});
