
    import Vue, { defineComponent, ref, watch } from 'vue';
    import { PsButton } from '@poplar-studio/design-system';
    import config from '../config';
    import { useUserStore } from '../store/users';
    import { useCompanyStore } from '../store/company';
    import { useMobileUiStore } from '../store/mobileUi';
    import { INavigationButton } from '@/types';
    import router from '../router/index';

    export default defineComponent({
        components: {
            PsButton,
        },
        setup() {
            const menuButtons = ref<INavigationButton[]>([]);
            const userStore = useUserStore();
            const companyStore = useCompanyStore();
            const mobileUiStore = useMobileUiStore();
            const routeLoaded = ref(false);

            router.afterEach((to) => {
                Vue.nextTick(() => {
                    routeLoaded.value = false;
                    if (to.meta && to.meta.title) {
                        routeLoaded.value = true;
                    }
                });
            });

            const currentRoute = (button: INavigationButton) => {
                let current = false;
                // @ts-ignore
                if (button.link.text === router.history.current.name) {
                    current = true;
                }

                if (!current && button.childButtons) {
                    for (const childB of button.childButtons) {
                        // @ts-ignore
                        if (childB.link.text === router.history.current.name) {
                            current = true;
                            break;
                        }
                    }
                }

                return current;
            };

            const buttonClicked = (button: INavigationButton) => {
                // Close the mobile menu on page change
                mobileUiStore.$patch({
                    sideBarActive: false,
                });

                router.push({
                    name: button.link.text,
                    params: button.link.params,
                });
            };

            const getCompanyId = () => {
                return userStore.isSuperAdmin ? companyStore.company.id : userStore.companyId;
            };

            watch(
                () => [
                    userStore.companyId,
                    companyStore.company.id,
                    userStore.isSuperAdmin,
                    userStore.isShopifyAdmin,
                    routeLoaded.value,
                ],
                function () {
                    const companyButtons: INavigationButton[] = [
                        {
                            text: 'Company details',
                            link: {
                                text: `company-details`,
                                params: {
                                    companyId: getCompanyId(),
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                        {
                            text: 'Unit settings',
                            link: {
                                text: `unit-settings`,
                                params: {
                                    companyId: getCompanyId(),
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                        {
                            text: 'Categories',
                            link: {
                                text: `categories`,
                                params: {
                                    companyId: getCompanyId(),
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                        {
                            text: 'Active URLs',
                            link: {
                                text: `active-urls`,
                                params: {
                                    companyId: getCompanyId(),
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                    ];

                    if (!userStore.isViewer) {
                        companyButtons.push({
                            text: 'Other settings',
                            link: {
                                text: `${config.v1Url}/#/company-detail/${getCompanyId()}`,
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: false,
                        });
                    }

                    const buttons: INavigationButton[] = [
                        {
                            text: 'Media library',
                            link: {
                                text: `media`,
                            },
                            class: userStore.isShopifyAdmin
                                ? 'side-container shopify-admin-nav'
                                : 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                    ];
                    if (!userStore.isShopifyAdmin) {
                        buttons.unshift({
                            text: 'Upload new media',
                            link: {
                                text: `media-upload`,
                            },
                            class: 'main-button',
                            tertiary: false,
                            v2: true,
                        });
                    }
                    if (userStore.isSuperAdmin) {
                        buttons.push({
                            text: 'Companies',
                            link: {
                                text: `companies`,
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                            childLabel: companyStore.company.name,
                            childButtons:
                                companyStore.company.id.length &&
                                router.history.current.path.includes('company') > 0
                                    ? companyButtons
                                    : undefined,
                        });
                    } else {
                        buttons.push({
                            text: 'Company settings',
                            link: {
                                text: `company-details`,
                                params: {
                                    companyId: getCompanyId(),
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                            childButtons: companyButtons,
                        });
                    }
                    buttons.push(
                        {
                            text: 'Users',
                            link: {
                                text: `users`,
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                        {
                            text: 'API keys',
                            link: {
                                text: `api-keys`,
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        },
                        {
                            text: 'Analytics',
                            link: {
                                text: `analytics`,
                                params: {
                                    companyId: userStore.isSuperAdmin ? null : userStore.companyId,
                                },
                            },
                            class: 'side-container',
                            tertiary: true,
                            v2: true,
                        }
                    );
                    menuButtons.value = buttons;
                },
                { deep: true }
            );

            return {
                buttonClicked,
                currentRoute,
                menuButtons,
                mobileUiStore,
                userStore,
            };
        },
    });
