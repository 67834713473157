
    import { defineComponent, watch, ref } from 'vue';
    import { useUserStore } from '../store/users';
    import { BDropdown, BDropdownItem, BDropdownDivider } from '@poplar-studio/design-system';
    import config from '../config';

    export default defineComponent({
        components: {
            BDropdown,
            BDropdownItem,
            BDropdownDivider,
        },
        setup() {
            const userName = ref('');
            const userProfile = ref('');
            const userStore = useUserStore();

            watch(
                () => userStore.user,
                function () {
                    userName.value = userStore.user.username;
                    userProfile.value = `${config.v1Url}/#/user/${userStore.user.id}`;
                }
            );

            const logout = () => {
                userStore.logout();
            };

            return {
                userName,
                BDropdown,
                BDropdownItem,
                BDropdownDivider,
                logout,
                userProfile,
                userStore,
            };
        },
    });
