import { defineStore } from 'pinia';

interface MobildUiState {
    sideBarActive: boolean;
}

export const useMobileUiStore = defineStore({
    id: 'mobileUi',
    state: (): MobildUiState => ({
        sideBarActive: false,
    }),
});
