import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { useMobileUiStore } from '../store/mobileUi';

Vue.use(VueRouter);

const title = 'Poplar CMS';

const routes: Array<RouteConfig> = [
    {
        path: '/analytics/:companyId?',
        name: 'analytics',
        component: () => import('../views/company-analytics.vue'),
        meta: {
            title: `Analytics`,
        },
    },
    {
        path: '/company/:companyId/unit-settings',
        name: 'unit-settings',
        component: () => import('../views/company/unit-settings.vue'),
        meta: {
            title: `Unit Settings`,
        },
    },
    {
        path: '/company/:companyId/details',
        name: 'company-details',
        component: () => import('../views/company/company-details.vue'),
        meta: {
            title: `Company Details`,
        },
    },
    {
        path: '/company/:companyId/categories',
        name: 'categories',
        component: () => import('../views/company/company-categories.vue'),
        meta: {
            title: `Company Details`,
        },
    },
    {
        path: '/company/:companyId/active-urls',
        name: 'active-urls',
        component: () => import('../views/company/company-origins.vue'),
        meta: {
            title: `Company URLs`,
        },
    },
    {
        path: '/companies/add',
        name: 'add-company',
        component: () => import('../views/company/add-company.vue'),
        meta: {
            title: `Add Company`,
        },
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import('../views/company/companies-list.vue'),
        meta: {
            title: `Companies`,
            extendedLayout: true,
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('../views/media/media-listing.vue'),
        meta: {
            title: `Media`,
            extendedLayout: true,
        },
    },
    {
        path: '/media/add',
        name: 'media-upload',
        component: () => import('../views/media/media-upload.vue'),
        meta: {
            title: `Media Upload`,
        },
    },
    {
        path: '/',
        name: 'log-in',
        component: () => import('../views/authentication/log-in.vue'),
        meta: {
            title: `log in`,
            unauthenticated: true,
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/users-list.vue'),
        meta: {
            title: `Users`,
            extendedLayout: true,
        },
    },
    {
        path: '/users/add',
        name: 'add-users',
        component: () => import('../views/users/add-users.vue'),
        meta: {
            title: `Add Users`,
            extendedLayout: true,
        },
    },
    {
        path: '/api-keys',
        name: 'api-keys',
        component: () => import('../views/api/api-key-list.vue'),
        meta: {
            title: `Api Keys`,
        },
    },
    {
        path: '*',
        redirect: '/media',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        const mobileUiStore = useMobileUiStore();
        let pageTitle = title;

        if (to.meta && to.meta.title) {
            pageTitle = `${to.meta.title} - ${title}`;
        }
        document.title = pageTitle;

        // Close the mobile menu on page change
        mobileUiStore.$patch({
            sideBarActive: false,
        });
    });
});

export default router;
