import { defineStore } from 'pinia';

interface BannerState {
    active: boolean;
    message: string;
    variant: string;
}

export const useBannerStore = defineStore({
    id: 'banners',
    state: (): BannerState => ({
        active: false,
        message: '',
        variant: '',
    }),
});
