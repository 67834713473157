import { IUser, IUserTypes } from '@poplar-studio/poplar-cms';
import config from '@/config';
import Api from '@/services/api';
import { defineStore } from 'pinia';
import Vue from 'vue';
import router from '../router';

interface UserState {
    isLoggedIn: boolean;
    user: IUser;
    companyId: string;
    isViewer: boolean;
    isSuperAdmin: boolean;
    isShopifyAdmin: boolean;
    isEditor: boolean;
}

const baseState = {
    isLoggedIn: false,
    user: {
        id: '',
        email: '',
        username: '',
        isActive: false,
        type: IUserTypes.VIEWER,
    },
    companyId: '',
    isViewer: false,
    isSuperAdmin: false,
    isShopifyAdmin: false,
    isEditor: false,
};

export const useUserStore = defineStore({
    id: 'users',
    state: (): UserState => baseState,

    actions: {
        setIsLoggedIn(value: boolean) {
            this.isLoggedIn = value;
        },
        setUser(user: IUser) {
            this.user = user;
            this.isViewer = user.type === IUserTypes.VIEWER;
            this.isEditor = user.type === IUserTypes.EDITOR;
            this.isShopifyAdmin = user.type === IUserTypes.SHOPIFY_ADMIN;
            this.isSuperAdmin = user.type === IUserTypes.SUPER_ADMIN;
        },
        setCompanyId(companyId: string) {
            this.companyId = companyId;
        },
        logout() {
            // Clear cookies and local storage
            Vue.$cookies.remove('userToken', '/', '.poplar.studio');
            Vue.$cookies.remove('appToken', '/', '.poplar.studio');

            this.isLoggedIn = false;
            this.user = {
                id: '',
                email: '',
                username: '',
                isActive: false,
                type: IUserTypes.VIEWER,
            };
            this.companyId = '';
            this.isViewer = false;
            this.isSuperAdmin = false;
            this.isShopifyAdmin = false;
            this.isEditor = false;

            // redirect user to V1 login page
            router.push({
                name: 'log-in',
            });
        },
        async checkCookies() {
            try {
                const cookieResponse = await Api.checkCookies();
                this.setIsLoggedIn(true);
                this.setUser(cookieResponse.data.user);
                const companyResponse = await Api.getUserCompany();
                this.setCompanyId(companyResponse.data.companies[0].id);
                return true;
            } catch (err) {
                this.logout();
                return false;
            }
        },
        async login(username: string, password: string) {
            const loginResponse = await Api.login({ username, password });
            this.setIsLoggedIn(true);
            this.setUser(loginResponse.data.user);
            const companyResponse = await Api.getUserCompany();
            this.setCompanyId(companyResponse.data.companies[0].id);
        },
    },
});
