import Axios, { AxiosResponse } from 'axios';
import config from '@/config';
import { useUserStore } from '../store/users';
import { Iitem, IOriginItem } from '../types';
import { IFullCompany, IMediaPayload } from '@poplar-studio/poplar-cms';

const axios = Axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
});

axios.interceptors.request.use(function (config) {
    // Depending on how the user navigates to this app, some requests can be cached and cause CORS errors with origins.
    // Adding a timestamp will ignore any browser caching. Query can be changed if it conflicts in the future.
    config.params = { ...config.params, timestamp: Date.now() };
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 403) {
            const userStore = useUserStore();
            userStore.logout();
        } else {
            return Promise.reject(error);
        }
    }
);
export default {
    login(body: any): Promise<AxiosResponse> {
        return axios.post(`/v1/admin/login`, body);
    },
    checkCookies(): Promise<AxiosResponse> {
        return axios.get(`/v1/cookies`);
    },
    getUserCompany(): Promise<AxiosResponse> {
        return axios.get(`/v1/user/current/companies`);
    },
    getCompany(id: string): Promise<AxiosResponse> {
        return axios.get(`/v1/companies/${id}`);
    },
    updateCompany(
        id: string,
        body: {
            company: IFullCompany;
        }
    ): Promise<AxiosResponse> {
        return axios.patch(`/v1/companies/${id}`, body);
    },
    createCompany(body: { company: IFullCompany }): Promise<AxiosResponse> {
        return axios.post(`/v1/companies/`, body);
    },
    getAllCompanies(): Promise<AxiosResponse> {
        return axios.get(`/v1/companies`);
    },
    getCompanyMedia(filter: string | null): Promise<AxiosResponse> {
        return axios.get(`/v1/admin/media?${filter}`);
    },

    getCompanyMultipleMedia(body: {
        mediaIds: (string | null | undefined)[];
    }): Promise<AxiosResponse> {
        return axios.post(`/v1/multiple/media`, body);
    },
    getUnitTypes(): Promise<AxiosResponse> {
        return axios.get(`/v1/unit-types`);
    },
    getEnvironments(): Promise<AxiosResponse> {
        return axios.get(`/v1/media-environments`);
    },
    addCompanyCategory(
        companyId: string,
        body: {
            category: Iitem;
        }
    ): Promise<AxiosResponse> {
        return axios.post(`/v1/companies/${companyId}/categories`, body);
    },
    updateCompanyCategory(
        companyId: string,
        body: {
            category: Iitem;
        },
        id: string
    ): Promise<AxiosResponse> {
        return axios.put(`/v1/companies/${companyId}/categories/${id}`, body);
    },
    deleteCompanyCategory(companyId: string, id: string): Promise<AxiosResponse> {
        return axios.delete(`/v1/companies/${companyId}/categories/${id}`);
    },
    addCompanyOrigin(
        companyId: string,
        body: {
            origin: IOriginItem;
        }
    ): Promise<AxiosResponse> {
        return axios.post(`/v1/companies/${companyId}/origins`, body);
    },
    updateCompanyOrigin(
        companyId: string,
        body: {
            origin: IOriginItem;
        },
        id: string
    ): Promise<AxiosResponse> {
        return axios.put(`/v1/companies/${companyId}/origins/${id}`, body);
    },
    deleteCompanyOrigin(companyId: string, id: string): Promise<AxiosResponse> {
        return axios.delete(`/v1/companies/${companyId}/origins/${id}`);
    },
    uploadFile(body: FormData): Promise<AxiosResponse> {
        return axios.post(`/v2/media/upload`, body, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    uploadMedia(body: IMediaPayload): Promise<AxiosResponse> {
        return axios.post(`/v1/vr/media`, body);
    },
    getUsers(): Promise<AxiosResponse> {
        return axios.get(`/v1//users`);
    },
    createUser(body: any): Promise<AxiosResponse> {
        return axios.post(`/v1//user`, body);
    },
    getApiKeys(): Promise<AxiosResponse> {
        return axios.get(`/v1/apps`);
    },
    createApiKey(body: any): Promise<AxiosResponse> {
        return axios.post(`/v1/apps`, body);
    },
    deleteApiKey(id: any): Promise<AxiosResponse> {
        return axios.delete(`/v1/apps/${id}`);
    },
};
