
    import { PsAppLayout, PsTopNav, PsBanner } from '@poplar-studio/design-system';
    import SideBarNav from './components/SideBarNav.vue';
    import HeaderLinks from './components/HeaderLinks.vue';
    import Vue, { defineComponent, watch, ref } from 'vue';
    import { useUserStore } from './store/users';
    import { useBannerStore } from './store/banners';
    import { useMobileUiStore } from './store/mobileUi';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBars } from '@fortawesome/free-solid-svg-icons';
    import config from './config';
    import router from './router/index';

    export default defineComponent({
        components: {
            PsAppLayout,
            PsTopNav,
            SideBarNav,
            HeaderLinks,
            FontAwesomeIcon,
            PsBanner,
        },
        setup() {
            const userStore = useUserStore();
            const bannerStore = useBannerStore();
            const mobileUiStore = useMobileUiStore();
            const extendedLayout = ref(false);

            const url = `${config.v1Url}/#/media`;

            router.afterEach((to) => {
                Vue.nextTick(() => {
                    // @ts-ignore
                    extendedLayout.value =
                        to.meta && to.meta.extendedLayout ? to.meta.extendedLayout : false;

                    if (to.meta && !to.meta.unauthenticated && !userStore.isLoggedIn) {
                        userStore.checkCookies();
                    }
                });
            });

            bannerStore.$subscribe((mutation, state) => {
                // Wait for 5 seconds then remove alert
                if (state.active) {
                    window.scrollTo(0, 0);
                    setTimeout(function () {
                        bannerStore.$reset();
                    }, 8000);
                }
            });

            const toggleMobileMenu = () => {
                mobileUiStore.$patch({
                    sideBarActive: !mobileUiStore.$state.sideBarActive,
                });
            };

            const closeMobileMenu = () => {
                if (window.innerWidth > 767) {
                    mobileUiStore.$patch({
                        sideBarActive: false,
                    });
                }
            };

            window.addEventListener('resize', closeMobileMenu);

            return {
                userStore,
                extendedLayout,
                url,
                bannerStore,
                faBars,
                toggleMobileMenu,
                mobileUiStore,
            };
        },
    });
